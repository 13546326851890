import { makeMenuService, MenuItem } from '@jakismig/client'
import { TopBar } from '@jakismig/common'
import { useService } from 'client'
import React, { useEffect, useState } from 'react'
import { smigLogo } from 'resources'
import { LogoBar } from './LogoBar'
import { MobileMenu } from './MobileMenu'
import { TopMenu } from './TopMenu'

interface Props {}

export const TopMenuContainer: React.FC<Props> = (props) => {
  const menuService = useService(makeMenuService)

  const [menuItems, setMenuItems] = useState<MenuItem[]>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    menuService
      .getMenuItems()
      .then(setMenuItems)
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }, [])

  if (loading) return <LogoBar logoUrl={smigLogo} />
  if (!menuItems) return <TopBar />
  return (
    <>
      <div className="md:hidden">
        <MobileMenu logoUrl={smigLogo} menuItems={menuItems} />
      </div>
      <div className="hidden md:block">
        <TopMenu logoUrl={smigLogo} menuItems={menuItems} />
      </div>
    </>
  )
}
