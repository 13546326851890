import { Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { MenuItem } from '@jakismig/client'
import { useState, Fragment } from 'react'

interface Props {
  item: MenuItem
  mainMenu?: boolean
}

export const MenuEntry: React.FC<Props> = ({ item, mainMenu }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const hasChildren = item.children.length > 0

  const liStyles = mainMenu
    ? 'flex gap-1 items-center  relative h-full'
    : 'py-3 px-10 flex gap-4 justify-between break-words  transition ease-in-out duration-200  hover:bg-gray-50'

  const ulStyles = mainMenu
    ? { top: '72px', borderColor: '#812984', width: '240px' }
    : {
        left: '240px',
        borderColor: '#812984',
        width: '240px',
        marginTop: '-40px',
      }

  return (
    <li
      className={liStyles}
      onMouseEnter={() => hasChildren && setIsOpen(true)}
      onMouseLeave={() => hasChildren && setIsOpen(false)}
    >
      <a
        href={item.href}
        className="hover:opacity-80  transition ease-in-out duration-200 w-full break-words whitespace-pre-wrap"
      >
        {item.title.toUpperCase()}
      </a>
      {hasChildren && (
        <>
          <ChevronDownIcon className="w-4" />
          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition-opacity transform  ease-out duration-300 origin-top"
            enterFrom="opacity-0 scale-y-0"
            enterTo="opacity-100 scale-y-100"
          >
            <ul
              className="absolute border-t-4 bg-white py-6 shadow-md"
              style={ulStyles}
            >
              {item.children.map((item) => (
                <MenuEntry key={item.title} item={item} />
              ))}
            </ul>
          </Transition>
        </>
      )}
    </li>
  )
}
