import { Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { MenuItem } from '@jakismig/client'
import React, { Fragment, useState } from 'react'
import { MobileMenuEntry } from './MobileMenuEntry'

interface Props {
  logoUrl: string
  menuItems: MenuItem[]
}

export const MobileMenu: React.FC<Props> = ({ logoUrl, menuItems }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  return (
    <div className="relative">
      <div
        className="w-full px-20  shadow-md flex justify-between items-center bg-white relative z-50"
        style={{ height: '72px' }}
      >
        <a href="https://smig.pl/">
          <img
            src={logoUrl}
            alt="smig_logo"
            className="cursor-pointer  w-10"
            style={{ maxWidth: 'none' }}
          ></img>
        </a>
        <div>
          <MenuIcon
            className="w-6 cursor-pointer text-gray-400"
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
        <Transition as={Fragment} show={menuOpen}>
          <ul
            className="absolute bg-white border-t-4 p-8  shadow-md border-gray-300 font-semibold text-sm w-4/5 max-h"
            style={{ color: 'rgba(0,0,0,0.6)', top: '72px', left: '10%' }}
          >
            {menuItems.map((item) => (
              <MobileMenuEntry item={item} key={item.title} />
            ))}
          </ul>
        </Transition>
      </div>
    </div>
  )
}
