import React from 'react'

interface Props {
  logoUrl: string
}

export const LogoBar: React.FC<Props> = ({ logoUrl }) => {
  return (
    <div className="relative">
      <div
        className="w-full px-20 md:px-8   shadow-md flex justify-between items-center bg-white relative z-50"
        style={{ height: '72px' }}
      >
        <a href="https://smig.pl/">
          <img
            src={logoUrl}
            alt="smig_logo"
            className="cursor-pointer  w-10 md:w-16"
            style={{ maxWidth: 'none' }}
          ></img>
        </a>
      </div>
    </div>
  )
}
