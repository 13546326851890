import { FormOption } from '@jakismig/client'
import React from 'react'
import { Choice } from './stepperReducer'

interface Props {
  optionsToDisplay: FormOption[]
  selectOption: (newChoice: Choice, stepIdx: number) => void
  stepIdx: number
  onDetailsClick: (
    optionIdx: number,
  ) => (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export const StepContent: React.FC<Props> = ({
  optionsToDisplay,
  selectOption,
  stepIdx,
  onDetailsClick,
}) => {
  const doubleColumn = optionsToDisplay.length >= 3

  return (
    <div className="h-full p-2 sm:p-6">
      <div
        className={`flex flex-row gap-2  ${
          doubleColumn
            ? 'flex-wrap justify-start flex-row'
            : 'justify-evenly flex-col'
        }  h-full  items-center`}
      >
        {optionsToDisplay.map((option, idx) => {
          return (
            <div
              key={option._id}
              style={{
                height: calculateColumnHeight(optionsToDisplay.length),
                width: doubleColumn ? 'calc(50% - 0.25rem)' : '100%',
                maxHeight: doubleColumn ? '50%' : '100%',
                maxWidth: '750px',
              }}
              className={`border cursor-pointer flex flex-col transition transform ease-in-out duration-300 smig-border hover:shadow-md`}
              onClick={() =>
                selectOption(
                  {
                    optionId: option._id,
                    conflictingWith: option.conflictingWith,
                  },
                  stepIdx,
                )
              }
            >
              <div
                className={`w-full  h-full flex justify-center items-center relative`}
              >
                {option.description && (
                  <svg
                    onClick={onDetailsClick(idx)}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 absolute right-2 top-2 text-primary bg-white rounded-full transform hover:scale-125"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                <div
                  className="w-full h-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${option.imageUrl})` }}
                />
              </div>
              <div className="border-t w-full px-4 py-1 text-center font-semibold">
                {option.label}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const calculateColumnHeight = (items: number) => {
  if (items < 3) return '100%'
  const rows = (2 * Math.round(items / 2)) / 2
  return `calc(${100 / rows}% - 0.5rem)`
}
