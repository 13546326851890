import { useService } from 'client'
import { ErrorView } from '@jakismig/common'
import { makeFormTemplateService } from '@jakismig/client'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { smigLogo } from 'resources'

interface Props {}

export const Home: React.FC<Props> = (props) => {
  const history = useHistory()

  const [error, setError] = useState<string | undefined>()

  const formTemplateService = useService(makeFormTemplateService)
  const [templateNames, setTemplateNames] = useState<
    { _id: string; name: string }[]
  >()

  useEffect(() => {
    formTemplateService
      .getTemplateNames()
      .then(setTemplateNames)
      .catch((error) => {
        console.log(error)
        const msg =
          'Coś poszło nie tak podczas ładowania dostępnych formularzy :('
        setError(msg)
      })
  }, [])

  if (error) return <ErrorView msg={error} />
  if (!templateNames) return null

  return (
    <div className="container mx-auto p-8 max-w-md">
      <div className="flex flex-col items-center gap-6">
        <img src={smigLogo} alt="smig_logo"></img>
        <h3 className="font-semibold text-lg">
          Jaki rodzaj prac Cię interesuje?
        </h3>

        {templateNames.map((form) => {
          return (
            <button
              className="w-full py-3 text-white font-semibold rounded-sm bg-primary"
              key={form._id}
              onClick={() => history.push(`/${form.name}/formularz`)}
            >
              {form.name}
            </button>
          )
        })}
      </div>
    </div>
  )
}
