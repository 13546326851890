import { FormStep } from '@jakismig/client'
import _ from 'lodash'
import React, { useEffect, useReducer } from 'react'
import { Step } from './Step'
import { stepperReducer } from './stepperReducer'

interface Props {
  steps: FormStep[]
  navigateToResult: (choices: (string | undefined)[]) => void
  navigateHome: () => void
}

export const Stepper: React.FC<Props> = ({
  steps,
  navigateToResult,
  navigateHome,
}) => {
  const [stepperData, dispatch] = useReducer(stepperReducer, {
    formChoices: [],
    stepIdx: 0,
  })

  useEffect(() => {
    if (
      _.compact(stepperData.formChoices).length === steps.length &&
      stepperData.stepIdx === steps.length - 1
    ) {
      const choices = stepperData.formChoices.map((choice) => choice?.optionId)
      navigateToResult(choices)
    }
  }, [stepperData])

  return (
    <div className="flex-grow flex flex-col justify-center">
      <div
        className="container bg-secondary smig-border h-full shadow-md mx-auto flex flex-col max-w-screen-md md:my-8"
        style={{ maxHeight: '1000px' }}
      >
        <div className=" h-full overflow-hidden">
          <Step
            steps={steps}
            formChoices={stepperData.formChoices}
            selectOption={(newChoice, stepIdx) => {
              dispatch({ type: 'SELECT_OPTION', newChoice, stepIdx })
              dispatch({ type: 'MOVE_FORWARD', steps })
            }}
            resetForm={() =>
              dispatch({ type: 'SET_FORM_DATA', stepIdx: 0, choices: [] })
            }
            navigateHome={navigateHome}
            stepIdx={stepperData.stepIdx}
          />
        </div>
      </div>
    </div>
  )
}
