import React from 'react'
import { useHistory, useParams } from 'react-router'
import { Button } from '.'

interface Props {
  msg: string
}
const imgUrl = `${process.env.PUBLIC_URL}/smig_err.png`

export const ErrorView: React.FC<Props> = ({ msg }) => {
  const history = useHistory()

  return (
    <div className=" w-full h-full flex justify-center items-center bg-white">
      <div className="flex flex-col md:flex-row h-80 gap-4 px-8">
        <img src={imgUrl} alt="smig_blad" className="max-w-sm w-full" />
        <div className=" h-3/5 flex flex-col justify-between gap-4 ">
          <div>
            <p className="text-2xl font-semibold text-right">Oops!</p>
            <p className="text-xl text-right">{msg}</p>
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => history.push('/')}
              className="bg-black text-white"
            >
              Strona główna
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const FormErrorView: React.FC<Props> = ({ msg }) => {
  const history = useHistory()
  const params = useParams<{ formName: string }>()

  return (
    <div className=" w-full h-full flex justify-center items-center">
      <div className="flex flex-col md:flex-row h-80 gap-4">
        <img src={imgUrl} alt="smig_blad" className="max-w-sm" />
        <div className=" h-3/5 flex flex-col justify-between gap-4">
          <div>
            <p className="text-2xl font-semibold text-right">Oops!</p>
            <p className="text-xl text-right">{msg}</p>
          </div>
          <div className="flex flex-col gap-2 items-end justify-end">
            <Button
              onClick={() => history.push('/' + params.formName + '/formularz')}
              className="bg-black text-white"
            >
              Wypełnij ponownie formularz
            </Button>
            <Button
              onClick={() => history.push('/')}
              className="bg-black text-white"
            >
              Strona główna
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
