import { AxiosInstance } from 'axios'
import { nanoid } from 'nanoid'
import { FormTemplate } from './formTemplate'

const FORM_TEMPLATE_URL = 'formTemplate'

export const makeFormTemplateService = (api: AxiosInstance) => ({
  getTemplateNames: (): Promise<{ _id: string; name: string }[]> =>
    api.get(FORM_TEMPLATE_URL).then((response) => response.data),
  getTemplate: (id: string): Promise<FormTemplate> =>
    api.get(`${FORM_TEMPLATE_URL}/${id}`).then((response) => response.data),
  updateTemplate: (template: FormTemplate): Promise<void> =>
    api.patch(FORM_TEMPLATE_URL, template).then((response) => response.data),
  deleteTemplate: (id: string): Promise<void> =>
    api.delete(`${FORM_TEMPLATE_URL}/${id}`).then((response) => response.data),
  initializeTemplate: (): Promise<void> =>
    api.post(FORM_TEMPLATE_URL, {
      _id: nanoid(),
      name: nanoid(5),
      formSteps: [],
    }),
})
