import { PlusIcon, XIcon } from '@heroicons/react/solid'
import { MenuItem } from '@jakismig/client'
import React, { useState } from 'react'

interface Props {
  item: MenuItem
}

export const MobileMenuEntry: React.FC<Props> = ({ item }) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <li className=" cursor-pointer  px-6 transition duration-100 ease-in-out ">
      <div
        className="flex justify-between items-center w-full border-b border-gray-100 "
        onClick={() => setOpen(!open)}
      >
        <a
          href={item.href === '#' ? undefined : item.href}
          className="h-full w-full py-3 hover:opacity-70"
        >
          {item.title}
        </a>
        {item.children.length > 0 && (
          <div className="pl-4">
            {open ? (
              <XIcon className="w-4 text-primary" />
            ) : (
              <PlusIcon className="w-4 text-primary" />
            )}
          </div>
        )}
      </div>
      {open && (
        <ul className="">
          {item.children.map((item) => (
            <MobileMenuEntry item={item} />
          ))}
        </ul>
      )}
    </li>
  )
}
