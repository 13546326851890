import { ShoppingCartIcon } from '@heroicons/react/solid'
import { OptionsDictionary, ProductMatch } from '@jakismig/client'
import { LargeRibbon } from '@jakismig/common'
import React, { useState } from 'react'
import { OptionInfoDialog } from './OptionInfoDialog'

interface Props {
  productDisplayRef: React.RefObject<HTMLDivElement>
  optionsDictionary: OptionsDictionary
  productToDisplay: ProductMatch
  choices: string[]
}

export const ProductDisplay: React.FC<Props> = ({
  productDisplayRef,
  optionsDictionary,
  productToDisplay,
  choices,
}) => {
  const [infoDialogOptionId, setInfoDialogOptionId] = useState<string | null>(
    null,
  )
  return (
    <>
      <OptionInfoDialog
        onDetailsClose={() => setInfoDialogOptionId(null)}
        infoDialogOptionId={infoDialogOptionId}
        productToDisplay={productToDisplay.product}
        optionsDictionary={optionsDictionary}
      />
      <div
        className="smig-border shadow-sm p-8 pb-4 box-border relative m-4 md:w-10/12  sm:mx-auto"
        ref={productDisplayRef}
      >
        <div className="flex xl:flex-row flex-col justify-between  gap-4">
          <LargeRibbon
            variant={productToDisplay.matches === 1 ? 'success' : 'error'}
          />
          <div className="h-full xl:pr-4 mx-auto order-2 w-full xl:w-auto">
            <div
              className="xl:w-72 xl:h-72 w-full h-64 bg-contain bg-center bg-no-repeat rounded-lg "
              style={{
                backgroundImage: `url(${productToDisplay.product.imageUrl})`,
              }}
            />
          </div>
          <div className="flex flex-col justify-start items-center w-full xl:mt-0 order-4 xl:order-2">
            <ul className="h-full flex flex-col justify-start">
              {choices.map((choice) => {
                const isMatching = productToDisplay.product.matchingOptionIds.includes(
                  choice,
                )
                const hasComment = Boolean(
                  productToDisplay.product.optionComments &&
                    productToDisplay.product.optionComments[choice],
                )
                return (
                  <li
                    key={`choice-${choice}`}
                    className={`  font-semibold text-xl flex flex-row items-center justify-between gap-4  p-2 border-b border-gray-200 ${
                      hasComment ? 'cursor-pointer' : ''
                    }`}
                    onClick={() => hasComment && setInfoDialogOptionId(choice)}
                  >
                    <div className="flex justify-start items-center gap-4">
                      <div
                        className={`relative ${
                          isMatching ? 'text-green-600' : 'text-gray-300'
                        }`}
                      >
                        {isMatching ? CheckCircle : XCircle}
                      </div>
                      <span
                        className={`font-semibold ${
                          isMatching ? 'text-black' : 'text-gray-300'
                        }`}
                      >
                        {optionsDictionary[choice]?.label.toUpperCase()}
                      </span>
                    </div>
                    <div className="text-gray-400 h-5 w-5">
                      {hasComment && Info}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="w-full order-1  xl:order-3">
            <h3 className="font-semibold text-2xl mb-2 text-right pl-20 xl:pl-0">
              {productToDisplay.product.name}
            </h3>
            <p className="text-sm text-gray-700  text-justify">
              {productToDisplay.product.description}
            </p>
          </div>
        </div>
        <div className="flex sm:justify-end items-center w-full gap-4 mt-4 justify-center flex-col sm:flex-row">
          <a
            href={productToDisplay.product.productUrl}
            target="_blank"
            rel="noreferrer"
          >
            <button
              disabled={!Boolean(productToDisplay.product.productUrl)}
              className="disabled:opacity-50 text-md font-semibold  px-4 py-1 rounded-sm disabled:cursor-not-allowed hover:opacity-80 text-gray-900 transition ease-in-out duration-100"
            >
              Dowiedz się więcej
            </button>
          </a>
          <a
            href={productToDisplay.product.shopUrl}
            target="_blank"
            rel="noreferrer"
          >
            <button
              disabled={!Boolean(productToDisplay.product.shopUrl)}
              className="disabled:opacity-50 text-md font-semibold bg-green-600 px-4 py-1 rounded-sm text-white hover:opacity-80 transition ease-in-out duration-100 flex gap-2 items-center"
            >
              <span>Zobacz w sklepie</span>
              <ShoppingCartIcon className="w-4 h-4" />
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

const CheckCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

const XCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

const Info = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
      clipRule="evenodd"
    />
  </svg>
)
