import {
  moveBack,
  moveForward,
  moveToStep,
  selectOption,
  setFormData,
} from './actions'
import { Choice, StepperReducerAction } from './actionTypes'

export interface StepperData {
  formChoices: (Choice | undefined)[]
  stepIdx: number
}

export const stepperReducer = (
  previousState: StepperData,
  action: StepperReducerAction,
): StepperData => {
  switch (action.type) {
    case 'SELECT_OPTION':
      return selectOption(previousState, action.newChoice, action.stepIdx)
    case 'MOVE_BACK':
      return moveBack(previousState)
    case 'MOVE_TO_STEP':
      return moveToStep(previousState, action.stepToMove)
    case 'MOVE_FORWARD':
      return moveForward(previousState, action.steps)
    case 'SET_FORM_DATA':
      return setFormData(previousState, action.choices, action.stepIdx)
  }
}
