import { OptionsDictionary, Product } from '@jakismig/client'
import { Dialog } from '@jakismig/common'
import React, { useEffect, useState } from 'react'

interface Props {
  onDetailsClose: () => void
  infoDialogOptionId: string | null
  productToDisplay: Product
  optionsDictionary: OptionsDictionary
}

interface DialogContent {
  label: string
  description: string
}

export const OptionInfoDialog: React.FC<Props> = ({
  onDetailsClose,
  productToDisplay,
  infoDialogOptionId,
  optionsDictionary,
}) => {
  const [dialogContent, setDialogContent] = useState<DialogContent>()

  useEffect(() => {
    if (!infoDialogOptionId || !productToDisplay.optionComments) return
    const label = optionsDictionary[infoDialogOptionId]?.label ?? ''
    const description =
      productToDisplay.optionComments[infoDialogOptionId] ?? ''
    setDialogContent({ label, description })
  }, [infoDialogOptionId])

  return (
    <Dialog
      open={Boolean(infoDialogOptionId)}
      onClose={onDetailsClose}
      className=" w-full max-w-sm"
    >
      <h5 className=" font-semibold text-lg">{dialogContent?.label}</h5>
      <p className="whitespace-pre-line">{dialogContent?.description}</p>
    </Dialog>
  )
}
