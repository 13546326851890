import {
  makeProductService,
  OptionsDictionary,
  ProductMatch,
} from '@jakismig/client'
import { CircularLoading, FormErrorView } from '@jakismig/common'
import { useService } from 'client'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ProductCarousel } from './ProductCarousel'
import { ProductDisplay } from './ProductDisplay'
import { ResultInfo } from './ResultInfo'

interface Props {
  optionsDictionary: OptionsDictionary
  formId: string
}

const resultError = 'Coś poszło nie tak podczas ustalania wyniku formularza :('

const getChoices = (
  search: string,
  optionsDictionary: OptionsDictionary,
): Promise<string[]> =>
  new Promise((resolve, reject) => {
    const queryStrings = qs.parse(search.substring(1))
    const choices = Object.values(queryStrings) as string[]
    choices.forEach((choice) => {
      if (!optionsDictionary[choice]) return reject(resultError)
    })
    return resolve(choices)
  })

export const Result: React.FC<Props> = ({ formId, optionsDictionary }) => {
  const productService = useService(makeProductService)
  const history = useHistory()
  const params = useParams<{ formName: string }>()

  const [error, setError] = useState<string | undefined>()

  const productDisplayRef = useRef<HTMLDivElement>(null)

  const [choices, setChoices] = useState<string[]>()
  const [matches, setMatches] = useState<ProductMatch[]>()
  const [productToDisplay, setProductToDisplay] = useState<ProductMatch>()

  useEffect(() => {
    if (!choices) return
    productService
      .getResults(formId, choices)
      .then((result) => setMatches(result))
      .catch((error) => {
        setError(resultError)
      })
  }, [choices])

  useEffect(() => {
    getChoices(history.location.search, optionsDictionary)
      .then(setChoices)
      .catch(setError)
  }, [history.location.search])

  useEffect(() => {
    if (matches === undefined) return
    setProductToDisplay(matches.sort(sortProducts)[0])
  }, [matches])

  const hasPerfectMatches = Boolean(
    matches?.find((match) => match.matches === 1),
  )

  if (error) return <FormErrorView msg={error} />

  return (
    <>
      <CircularLoading loading={matches === undefined} />
      {matches && choices && productToDisplay && (
        <div className=" bg-secondary h-full overflow-y-auto">
          <div>
            <div className="container mx-auto pt-4">
              {!hasPerfectMatches && <ResultInfo />}
              <ProductDisplay
                optionsDictionary={optionsDictionary}
                productDisplayRef={productDisplayRef}
                productToDisplay={productToDisplay}
                choices={choices}
              />
              <div className="flex sm:flex-row justify-end md:w-10/12 sm:mx-auto mx-4 gap-4 flex-col">
                <button
                  className="font-semibold transition ease-in-out hover:bg-opacity-80 duration-100 bg-primary text-white text-sm py-2  px-4  rounded-sm  sm:w-auto w-full"
                  onClick={() => history.push(`/`)}
                >
                  Wróć do listy formularzy
                </button>
                <button
                  className="font-semibold transition ease-in-out hover:bg-opacity-80 duration-100 bg-primary text-white text-sm py-2  px-4  rounded-sm  sm:w-auto w-full"
                  onClick={() => history.push(`/${params.formName}/formularz/`)}
                >
                  Wypełnij formularz ponownie
                </button>
              </div>
            </div>
          </div>

          {matches.length > 0 && (
            <>
              <div className="flex flex-col justify-center my-4 bg-center bg-primary">
                <div className="container mx-auto ">
                  <p
                    className="text-white font-extrabold lg:text-3xl text-2xl px-8 py-2 lg:py-4"
                    style={{ textShadow: '0em 0.1em 0.1em rgba(0,0,0,0.4)' }}
                  >
                    POZOSTAŁE PRODUKTY
                  </p>
                </div>
              </div>
              <div className="container mx-auto mb-8">
                <ProductCarousel
                  setSelected={setProductToDisplay}
                  matches={matches.sort(sortProducts)}
                  selected={productToDisplay}
                  resultComparison={{ choices }}
                  productDisplayRef={productDisplayRef}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

const sortProducts = (a: ProductMatch, b: ProductMatch) =>
  b.matches - a.matches || b.product.priority - a.product.priority
