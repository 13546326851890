import { Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'

interface Props {
  loading: boolean
}

export const CircularLoading: React.FC<Props> = ({ loading }) => {
  const [render, setRender] = useState<boolean>(loading)

  useEffect(() => {
    if (loading) return setRender(true)
    setTimeout(() => {
      setRender(false)
    }, 500)
  }, [loading])

  return (
    <Transition
      as={Fragment}
      show={render}
      enter="transition duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition transform duration-1000"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
    >
      <div
        className="fixed top-0 left-0 bottom-0 right-0 bg-white"
        style={{ zIndex: 48 }}
      >
        <div
          className={`absolute top-1/3 bottom-1/2 left-1/2 right 1/2 -ml-14`}
        >
          <div
            className={`absolute w-24 h-24 top-2 left-2 rounded-full bg-black ${
              !loading && render ? 'animate-ping' : ''
            }`}
            style={{ zIndex: 51 }}
          />
          <div
            className="w-28 h-28 bg-cover bg-center rounded-full bg-white relative "
            style={{
              zIndex: 52,
              backgroundImage: `url(https://smig.pl/wp-content/uploads/2020/10/smig-chemia-budowlana-1.png)`,
            }}
          />
        </div>
      </div>
    </Transition>
  )
}
