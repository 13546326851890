import { ErrorView, SnackbarProvider } from '@jakismig/common'
import { ClientProvider } from 'client'
import { FormContainer } from 'components/FormContainer'
import { Home } from 'components/Home'
import { TopMenuContainer } from 'components/TopMenu'
import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

function App() {
  return (
    <div className="h-screen overflow-y-auto font-montserrat font-medium flex flex-col">
      <ClientProvider>
        <SnackbarProvider>
          {process.env.REACT_APP_IS_MOBILE !== 'true' && <TopMenuContainer />}
          <Router>
            <Switch>
              <Route path="/:formName">
                <FormContainer />
              </Route>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route>
                <ErrorView msg="W tym miejscu nic nie ma!" />
              </Route>
            </Switch>
          </Router>
        </SnackbarProvider>
      </ClientProvider>
    </div>
  )
}

export default App
