import { AxiosInstance } from 'axios'
import { Product, ProductMatch } from './product'

const PRODUCT_URL = 'product'

export const makeProductService = (api: AxiosInstance) => ({
  getProducts: (formId: string, search: string = ''): Promise<Product[]> =>
    api
      .get(`${formId}/${PRODUCT_URL}`, { params: { search } })
      .then((response) => response.data),
  updateProduct: (formId: string, product: Product): Promise<void> =>
    api
      .patch(`${formId}/${PRODUCT_URL}`, product)
      .then((response) => response.data),
  createProduct: (formId: string, product: Product): Promise<void> =>
    api
      .post(`${formId}/${PRODUCT_URL}`, product)
      .then((response) => response.data),
  deleteProduct: (id: string): Promise<void> =>
    api.delete(PRODUCT_URL + `/${id}`).then((response) => response.data),
  getResults: (formId: string, choices: string[]): Promise<ProductMatch[]> =>
    api
      .get(`${formId}/${PRODUCT_URL}/results`, { params: choices })
      .then((response) => response.data),
})
