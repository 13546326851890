import React from 'react'
import { ProductMatch } from '@jakismig/client'
import { Ribbon } from '@jakismig/common'

interface Props {
  match: ProductMatch
  onClick: () => void
  resultComparison?: {
    choices: string[]
  }
  className?: string
}

export const ProductCard: React.FC<Props> = ({
  match,
  onClick,
  resultComparison,
  className,
}) => {
  const matchResult = resultComparison
    ? resultComparison.choices.reduce(
        (matches, choice) =>
          match.product.matchingOptionIds.includes(choice)
            ? matches + 1
            : matches,
        0,
      )
    : null

  return (
    <div
      className={`inline-block bg-white w-48 shadow-sm smig-border transition ease-in-out duration-150 ${className}`}
      onClick={onClick}
    >
      {matchResult && resultComparison && (
        <div className="relative">
          <Ribbon variant={match.matches === 1 ? 'success' : 'error'} />
          <div className="absolute flex justify-end right-0 top-0 mx-2 my-1 ">
            <span
              className={`bg-white rounded-md px-1 shadow-sm  font-semibold ${
                matchResult === resultComparison.choices.length
                  ? 'text-green-600'
                  : 'text-red-600'
              }`}
            >
              {`${matchResult}/${resultComparison.choices.length}`}
            </span>
          </div>
        </div>
      )}
      <div
        className="w-full h-36 bg-no-repeat bg-center bg-contain"
        style={{
          backgroundImage: `url(${match.product.imageUrl})`,
          boxShadow: 'inset 17px 0 10px white, inset -17px 0 10px white',
        }}
      />
      <div className="w-48 text-center truncate font-semibold border-t p-2">
        {match.product.name}
      </div>
    </div>
  )
}
