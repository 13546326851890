import { Transition } from '@headlessui/react'
import {
  FormTemplate,
  makeFormTemplateService,
  OptionsDictionary,
} from '@jakismig/client'
import { CircularLoading, ErrorView } from '@jakismig/common'
import { useService } from 'client'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { smigBg } from 'resources'
import { Stepper } from './Form'
import { Result } from './Result'

interface Props {}

export const FormContainer: React.FC<Props> = () => {
  const params = useParams<{ formName: string }>()
  const history = useHistory()
  const route = useRouteMatch()

  const formTemplateService = useService(makeFormTemplateService)
  const [formTemplate, setFormTemplate] = useState<FormTemplate>()
  const [error, setError] = useState<string | undefined>()

  const [navigatingToResult, setNavigatingToResult] = useState<boolean>(false)

  useEffect(() => {
    formTemplateService.getTemplateNames().then((forms) => {
      const form = forms.find((form) => form.name === params.formName)
      if (!form) {
        return setError('Nie znaleziono takiego formularza.')
      }
      formTemplateService
        .getTemplate(form._id)
        .then((template) => setFormTemplate(template))
        .catch((error) => {
          console.log(error)
          const msg = 'Coś poszło nie tak podczas ładowania formularza :('
          setError(msg)
        })
    })
  }, [params.formName])

  const optionsDictionary = formTemplate?.formSteps.reduce(
    (dictionary, step) => {
      const stepOptions = step.options.reduce((options, option) => {
        options[option._id] = { ...option, stepId: step._id }
        return options
      }, {} as OptionsDictionary)
      return { ...dictionary, ...stepOptions }
    },
    {} as OptionsDictionary,
  )

  if (error) return <ErrorView msg={error} />

  const navigateToResult = (choices: (string | undefined)[]) => {
    setNavigatingToResult(true)
    setTimeout(() => {
      history.push({
        pathname: `/${params.formName}/wynik`,
        search: qs.stringify(choices),
      })
      setNavigatingToResult(false)
    }, 500)
  }

  const navigateHome = () => history.push('/')

  return (
    <>
      <CircularLoading loading={!formTemplate} />
      {formTemplate && (
        <>
          <Transition
            as={Fragment}
            show={navigatingToResult}
            enter="transition transform duration-500"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transition transform duration-500"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <div
              className="fixed top-0 left-0 bottom-0 right-0 bg-white overflow-hidden"
              style={{ zIndex: 39 }}
            />
          </Transition>
          <Switch>
            <Route path={route.path + '/wynik'} exact>
              <Result
                formId={formTemplate._id}
                optionsDictionary={optionsDictionary ?? {}}
              />
            </Route>
            <Route path={route.path + '/formularz'} exact>
              <Stepper
                steps={formTemplate.formSteps}
                navigateToResult={navigateToResult}
                navigateHome={navigateHome}
              />
              {process.env.REACT_APP_IS_MOBILE !== 'true' && <Background />}
            </Route>
            <Route>
              <ErrorView msg="W tym miejscu nic nie ma!" />
            </Route>
          </Switch>
        </>
      )}
    </>
  )
}

const Background = () => (
  <div
    style={{ zIndex: -1 }}
    className="w-full h-full absolute overflow-hidden"
  >
    <div
      style={{
        backgroundImage: `url(${smigBg})`,
      }}
      className="bg-fixed bg-cover opacity-70 w-full h-full filter grayscale"
    />
  </div>
)
