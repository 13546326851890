import { FormOption } from '@jakismig/client'
import { Dialog } from '@jakismig/common'
import React, { useEffect, useState } from 'react'

interface Props {
  onDetailsClose: () => void
  detailsDialog: {
    open: boolean
    optionIdx: number
  }
  options: FormOption[]
}

interface DialogContent {
  label: string
  description: string
}

export const OptionInfoDialog: React.FC<Props> = ({
  onDetailsClose,
  detailsDialog,
  options,
}) => {
  const [dialogContent, setDialogContent] = useState<DialogContent>()

  useEffect(() => {
    const option = options[detailsDialog.optionIdx]
    if (!option) return
    setDialogContent({ label: option.label, description: option.description })
  }, [detailsDialog])

  return (
    <Dialog
      onClose={onDetailsClose}
      open={detailsDialog.open}
      className=" w-full max-w-md"
    >
      <h3 className="font-semibold text-lg mb-4">{dialogContent?.label}</h3>
      <p>{dialogContent?.description}</p>
    </Dialog>
  )
}
