import _ from 'lodash'
import React, { useEffect, useState } from 'react'

type Severity = 'success' | 'error'

interface Props {}

export const SnackbarContext = React.createContext({
  pushMessage: (message: string, severity: Severity) => {},
})

const severityToBgColor = {
  success: 'bg-green-500',
  error: 'bg-red-500',
}

export const SnackbarProvider: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<{
    message: string
    severity: Severity
  } | null>(null)

  const pushMessage = (message: string, severity: Severity) => {
    setMessage({ message, severity })
  }

  const clearSnackbar = _.debounce(() => setMessage(null), 6000)

  useEffect(() => {
    clearSnackbar()
  }, [message])

  return (
    <SnackbarContext.Provider value={{ pushMessage }}>
      {message && (
        <div
          onMouseOver={() => clearSnackbar.cancel()}
          onMouseOut={clearSnackbar}
          style={{ zIndex: 2000 }}
          className={`fixed left-1/2 transform -translate-x-1/2 bottom-8 h-14 rounded-md ${
            severityToBgColor[message.severity]
          } shadow-md transition ease-in-out duration-200`}
        >
          <div className="relative w-full h-full py-4 px-16  flex justify-start items-center text-white">
            <svg
              onClick={() => setMessage(null)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 absolute  top-2 right-2 cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-white font-semibold">{message.message}</p>
          </div>
        </div>
      )}
      {props.children}
    </SnackbarContext.Provider>
  )
}
