import { Transition } from '@headlessui/react'
import { FormStep } from '@jakismig/client'
import React, { Fragment, useState } from 'react'
import { OptionInfoDialog } from './OptionInfoDialog'
import { StepContent } from './StepContent'
import { StepHeader } from './StepHeader'
import { Choice, getPossibleOptions, notEmpty } from './stepperReducer'

interface Props {
  steps: FormStep[]
  stepIdx: number
  formChoices: (Choice | undefined)[]
  selectOption: (newChoice: Choice, stepIdx: number) => void
  resetForm: () => void
  navigateHome: () => void
}

export const Step: React.FC<Props> = ({
  steps,
  formChoices,
  selectOption,
  stepIdx,
  resetForm,
  navigateHome,
}) => {
  const [transitioning, setTransitioning] = useState<boolean>(false)
  const [detailsDialog, setDetailsDialog] = useState({
    open: false,
    optionIdx: -1,
  })

  const onDetailsClick = (optionIdx: number) => (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    setDetailsDialog({ open: true, optionIdx })
    e.stopPropagation()
  }

  const onDetailsClose = () => setDetailsDialog({ open: false, optionIdx: -1 })
  const allPreviousConflicts = formChoices
    .slice(0, stepIdx)
    .flatMap((choice) => choice?.conflictingWith)
    .filter(notEmpty)

  const optionsToDisplay = getPossibleOptions(
    steps,
    stepIdx,
    allPreviousConflicts,
  )
  const step = steps[stepIdx]

  const onSelectOption = (newChoice: Choice, stepIdx: number) => {
    setTransitioning(true)
    setTimeout(() => selectOption(newChoice, stepIdx), 300)
    setTimeout(() => setTransitioning(false), 600)
  }

  return (
    <div className="w-full h-full">
      <OptionInfoDialog
        onDetailsClose={onDetailsClose}
        detailsDialog={detailsDialog}
        options={step.options}
      />

      <Transition
        as={Fragment}
        show={!transitioning && Boolean(step)}
        enter="transition transform duration-300"
        enterFrom="opacity-50 translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition  transform duration-300"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-50 -translate-x-full"
      >
        <div className="h-full w-full flex flex-col">
          <StepHeader
            step={step}
            resetForm={resetForm}
            navigateHome={navigateHome}
          />
          <StepContent
            stepIdx={stepIdx}
            optionsToDisplay={optionsToDisplay}
            onDetailsClick={onDetailsClick}
            selectOption={onSelectOption}
          />
        </div>
      </Transition>
    </div>
  )
}
