import { MenuItem } from '@jakismig/client'
import React from 'react'
import { MenuEntry } from './MenuEntry'

interface Props {
  logoUrl: string
  menuItems: MenuItem[]
}

export const TopMenu: React.FC<Props> = ({ logoUrl, menuItems }) => {
  return (
    <div className="relative">
      <div
        className="w-full px-8  shadow-md flex justify-between items-center bg-white relative z-50"
        style={{ height: '72px' }}
      >
        <a href="https://smig.pl/">
          <img
            src={logoUrl}
            alt="smig_logo"
            className="cursor-pointer  w-10 md:w-16"
            style={{ maxWidth: 'none' }}
          ></img>
        </a>
        <ul
          className="flex whitespace-nowrap gap-6 font-montserrat font-bold text-xs h-full "
          style={{ color: 'rgba(0,0,0,0.6)' }}
        >
          {menuItems.map((item) => (
            <MenuEntry key={item.title} item={item} mainMenu />
          ))}
        </ul>
      </div>
    </div>
  )
}
