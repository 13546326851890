import React from 'react'

interface Props {}

export const ResultInfo: React.FC<Props> = () => {
  return (
    <div className="smig-border md:w-10/12 sm:mx-auto mx-4 px-8 py-4 bg-bg-secondary relative shadow-sm">
      <div className=" text-blue-500 absolute right-1.5 top-1.5">{Info}</div>
      <p>
        Niestety nie posiadamy w ofercie produktu który idealnie spełnia
        wszystkie Twoje wymagania. 😔
      </p>
      <p className="font-semibold">
        Przedstawiamy za to inne nasze produkty, które spełniają część podanych
        wymogów:
      </p>
    </div>
  )
}

const Info = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
      clipRule="evenodd"
    />
  </svg>
)
