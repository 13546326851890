import _ from 'lodash'
import { FormStep } from '@jakismig/client'
import { Choice } from './actionTypes'
import { StepperData } from './stepperReducer'

export const getPossibleOptions = (
  steps: FormStep[],
  idx: number,
  allConflicts: string[],
) => steps[idx].options.filter((option) => !allConflicts.includes(option._id))

export const selectOption = (
  previous: StepperData,
  newChoice: Choice,
  stepIdx: number,
): StepperData => {
  const newFormChoices = _.cloneDeep(previous.formChoices)

  newFormChoices[stepIdx] = newChoice

  const conflictFreeChoices = newFormChoices.map((previousChoice) => {
    if (previousChoice?.optionId === newChoice.optionId) return previousChoice
    const isConflicting = previousChoice?.conflictingWith.includes(
      newChoice.optionId,
    )
    if (isConflicting) return undefined
    return previousChoice
  })

  return {
    formChoices: conflictFreeChoices,
    stepIdx: stepIdx,
  }
}

export const moveBack = (previous: StepperData) => {
  const isFirst = previous.stepIdx === 0
  return {
    ...previous,
    stepIdx: isFirst ? previous.stepIdx : previous.stepIdx - 1,
  }
}

export const notEmpty = <T>(value: T | null | undefined): value is T =>
  value === undefined || value === null ? false : true

export const moveForward = (
  previous: StepperData,
  steps: FormStep[],
): StepperData => {
  const nextIdx = previous.stepIdx + 1
  const isLast = previous.stepIdx === steps.length - 1

  if (isLast) return previous

  const allConflicts = previous.formChoices
    .slice(0, nextIdx)
    .flatMap((choice) => choice?.conflictingWith)
    .filter(notEmpty)

  const nextOptions = getPossibleOptions(steps, nextIdx, allConflicts)
  const newStepperData = {
    ...previous,
    stepIdx: nextIdx,
  }

  if (nextOptions.length === 1) {
    const nextOption = nextOptions[0]
    const withSelect = selectOption(
      previous,
      { optionId: nextOption._id, conflictingWith: nextOption.conflictingWith },
      nextIdx,
    )
    return moveForward(withSelect, steps)
  }
  return newStepperData
}

export const setFormData = (
  previous: StepperData,
  choices: (Choice | undefined)[],
  stepIdx: number,
) => {
  return { formChoices: choices, stepIdx }
}
export const moveToStep = (previous: StepperData, stepToMove: number) => {
  return { ...previous, stepIdx: stepToMove }
}
