import { ProductMatch } from '@jakismig/client'
import React, { useEffect, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ProductCard } from './ProductCard'
import { isMobile } from 'react-device-detect'
interface Props {
  selected: ProductMatch
  setSelected: (product: ProductMatch) => void
  matches: ProductMatch[]
  resultComparison?: {
    choices: string[]
  }
  productDisplayRef: React.RefObject<HTMLDivElement>
}

export const ProductCarousel: React.FC<Props> = ({
  matches,
  selected,
  setSelected,
  productDisplayRef,
  ...rest
}) => {
  const [scrollInfo, setScrollInfo] = useState({
    isScrollableRight: false,
    leftButtonOpacity: 100,
    rightButtonOpacity: 100,
    isScrollableLeft: false,
  })
  const containerRef = useRef<HTMLElement>(null)

  const refs = useRef(
    new Array(matches.length)
      .fill(undefined)
      .map(() => React.createRef<HTMLDivElement>()),
  )

  const scrollPower = 200

  const scrollBy = (amount: number) => {
    if (containerRef.current)
      containerRef.current.scrollTo({
        behavior: 'smooth',
        left: containerRef.current.scrollLeft + amount,
      })
    updateScrollInfo()
  }

  const updateScrollInfo = () => {
    if (!containerRef.current) return
    if (containerRef.current.scrollWidth < containerRef.current.clientWidth)
      return setScrollInfo({
        isScrollableLeft: false,
        leftButtonOpacity: 0,
        isScrollableRight: false,
        rightButtonOpacity: 0,
      })
    const scrollLeft = containerRef.current.scrollLeft
    const maxScroll =
      containerRef.current.scrollWidth - containerRef.current.clientWidth
    setScrollInfo({
      isScrollableLeft: scrollLeft !== 0,
      leftButtonOpacity: scrollLeft,
      isScrollableRight: maxScroll !== scrollLeft,
      rightButtonOpacity: maxScroll - scrollLeft,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', updateScrollInfo)
    updateScrollInfo()
  }, [])

  return (
    <div className="relative overflow-hidden sm:overflow-visible">
      {scrollInfo.isScrollableLeft && isMobile && (
        <div
          className="bg-white absolute h-14 w-14 z-10 top-1/3 -left-4 rounded-full shadow-xl cursor-pointer flex justify-center items-center text-primary ring-1 ring-gray-200"
          style={{ opacity: scrollInfo.leftButtonOpacity / 10 }}
          onClick={() => scrollBy(-scrollPower)}
        >
          {ArrowLeft}
        </div>
      )}
      <ScrollContainer
        className="flex flex-row overflow-x-auto flex-nowrap gap-6 p-4 transform ease-in-out duration-200"
        style={{ scrollBehavior: 'smooth' }}
        hideScrollbars={isMobile}
        innerRef={containerRef}
        onScroll={updateScrollInfo}
      >
        {matches.map((match, idx) => {
          const isSelected = match.product._id === selected.product._id
          return (
            <div ref={refs.current[idx]} key={match.product._id}>
              <ProductCard
                onClick={() => {
                  productDisplayRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                  setSelected(match)
                }}
                match={match}
                className={`cursor-pointer ${
                  isSelected ? ' ring-primary ring-2' : ''
                }`}
                {...rest}
              />
            </div>
          )
        })}
      </ScrollContainer>
      {scrollInfo.isScrollableRight && isMobile && (
        <div
          className="bg-white absolute h-14 w-14 z-10 top-1/3 -right-4 rounded-full shadow-xl cursor-pointer flex justify-center items-center text-primary ring-1 ring-gray-200"
          style={{ opacity: scrollInfo.rightButtonOpacity / 10 }}
          onClick={() => scrollBy(scrollPower)}
        >
          {ArrowRight}
        </div>
      )}
    </div>
  )
}

const ArrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
)

const ArrowLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 16l-4-4m0 0l4-4m-4 4h18"
    />
  </svg>
)
