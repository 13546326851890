import axios, { AxiosInstance } from 'axios'
import React from 'react'

export const ClientContext = React.createContext<AxiosInstance>(axios)

export const ClientProvider: React.FC = (props) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_ADDRESS,
    withCredentials: false,
  })

  client.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response.data),
  )

  return (
    <ClientContext.Provider value={client}>
      {props.children}
    </ClientContext.Provider>
  )
}
