import React from 'react'

interface Props {
  className?: string
  onClick: () => void
  disabled?: boolean
}

export const Button: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`px-4 py-2 rounded-lg font-semibold transition ease-in-out duration-150 hover:bg-opacity-80 ${props.className} disabled:opacity-50`}
    >
      {props.children}
    </button>
  )
}
