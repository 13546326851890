import React from 'react'
import { RefreshIcon } from '@heroicons/react/solid'
import { HomeIcon } from '@heroicons/react/outline'

import { FormStep } from '@jakismig/client'

interface Props {
  step: FormStep
  resetForm: () => void
  navigateHome: () => void
}

export const StepHeader: React.FC<Props> = ({
  step,
  resetForm,
  navigateHome,
}) => {
  return (
    <div className="border-b p-2 sm:p-6">
      <div className="w-full flex flex-row justify-between items-start">
        <p className="font-semibold text-gray-900 px-2 text-2xl font-roboto">
          {step.title}
        </p>
        <div className="flex">
          <button
            className="semibold p-1 text-gray-900 transition transform ease-in-out duration-300 hover:bg-gray-100 rounded-full h-8 w-8 flex items-center justify-center"
            onClick={navigateHome}
          >
            <HomeIcon className="h-5 w-5" />
          </button>
          <button
            className="semibold p-1 text-gray-900 transition transform ease-in-out duration-300 hover:bg-gray-100 rounded-full h-8 w-8 flex items-center justify-center"
            onClick={resetForm}
          >
            <RefreshIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="px-2 text-gray-600 text-md">{step.description}</div>
    </div>
  )
}
