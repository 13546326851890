import React from 'react'
import { BadgeCheckIcon, XCircleIcon } from '@heroicons/react/solid'

interface Props {
  variant: 'success' | 'error'
}

export const Ribbon: React.FC<Props> = (props) => {
  const success = props.variant === 'success'
  const color = success ? 'bg-green-600' : 'bg-red-600'
  const bgColor = success ? 'bg-green-800' : 'bg-red-800'

  return (
    <div className={`absolute w-16 h-16 -left-1 -top-1 overflow-hidden`}>
      <div className="relative">
        <div
          className={`h-7 w-48 ${color} transform -rotate-45 -translate-x-20 translate-y-1  flex justify-center items-center z-10 font-bold text-white absolute`}
        ></div>
        <div
          className={`h-7 w-48 ${bgColor} transform -rotate-45 -translate-x-20 translate-y-1.5  flex  absolute`}
          style={{ zIndex: -1 }}
        />
      </div>
      <div className="absolute z-10 top-2 left-2 text-white">
        {success ? (
          <BadgeCheck variant="regular" />
        ) : (
          <XCircle variant="regular" />
        )}
      </div>
    </div>
  )
}

export const LargeRibbon: React.FC<Props> = (props) => {
  const success = props.variant === 'success'
  const color = success ? 'bg-green-600' : 'bg-red-600'

  return (
    <div className="absolute w-20 h-20 -left-0.5 -top-0.5 overflow-hidden">
      <div
        className={`h-12 w-40 ${color} transform -rotate-45 -translate-x-14`}
      />
      <div className="absolute z-10 top-3 left-3 text-white">
        {success ? <BadgeCheck variant="large" /> : <XCircle variant="large" />}
      </div>
    </div>
  )
}

const BadgeCheck = (props: { variant: 'regular' | 'large' }) => {
  return (
    <BadgeCheckIcon
      className={props.variant === 'regular' ? 'h-5 w-5' : 'h-7 w-7'}
    />
  )
}

const XCircle = (props: { variant: 'regular' | 'large' }) => {
  return (
    <XCircleIcon
      className={props.variant === 'regular' ? 'h-5 w-5' : 'h-7 w-7'}
    />
  )
}
